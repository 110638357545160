<template>
	<div>
		<div class="flex">
			<div class="w-full sm:w-2/3 md:w-3/5 lg:w-4/5 xl:w-1/2 mx-auto">

				<h1 class="text-2xl font-bold mb-5">Complete Profile</h1>

				<tabs v-model="tab" :tabs="tabs" class="mb-6" />

				<component :is="tab" />

			</div>
		</div>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center" ref="helpModal" @close="closeModal(modalKey, $refs.helpModal)">

			<div class="mb-10">
				<div class="text-xl font-bold mb-10">
					Update your profile!
				</div>

				<div class="font-light text-gray-500 text-sm">
					<p class="mb-2">This information will be used to verify your account and get your loans approved.</p>
				</div>
			</div>

			<button
				type="button"
				class="button border border-blue-500 hover:bg-blue-500 text-blue-500 hover:text-white"
				@click.prevent="$refs.helpModal.close"
			>
				Got it.
			</button>

		</modal>

	</div>
</template>

<script>
	export default {
		components: {
			profile: require('./Profile').default,
			documents: require('./Documents').default
		},
		data() {
			return {
				modalKey: 'settings-profile-help',
				tab: 'profile',
				tabs: [
					{name: 'profile', title: 'Update Profile'},
					{name: 'documents', title: 'Documents'},
				],
			}
		},
		computed: {
		},
		mounted() {
			this.openModal(this.modalKey, this.$refs.helpModal);
			
			if (this.$route.query.tab) {
				this.tab = this.$route.query.tab;
			}

		},
		watch: {
		},
		methods: {
		}
	}
</script>