<template>
	<div>
		
		<form @submit.prevent="submit" class="card border border-blue-200 pt-16 pb-10 lg:px-10 xl:px-16" key="company">
				
			<template v-if="getFormError(form)">
				<div class="alert alert-red-soft mb-10">
					<span class="alert-icon">!</span>
					<span class="">{{ getFormError(form) }}</span>
				</div>
			</template>

			<form-group leftIcon="person-outline" name="name" v-model="fullName" :disabled="true">
				Full Name
			</form-group>

			<form-group type="email" leftIcon="mail-outline" name="email" v-model="email" :disabled="true">
				Email Address
			</form-group>

			<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
				<div class="col-span-1">
					<form-group
						type="date"
						leftIcon="calendar-outline"
						name="date_of_birth"
						:form="form"
						v-model="form.data.date_of_birth.value">
						Date Of Birth
					</form-group>
				</div>
			</div>

			<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
				<div class="col-span-1">
					<form-group
						type="select"
						:options="genders"
						leftIcon="transgender-outline"
						name="gender"
						:form="form"
						v-model="form.data.gender.value">
						Gender
					</form-group>
				</div>
				<div class="col-span-1">
					<form-group
						type="select"
						:options="marital_statuses"
						leftIcon="body-outline"
						name="marital_status"
						:form="form"
						v-model="form.data.marital_status.value"
					>
						Marital Status
					</form-group>
				</div>
			</div>
			<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
				<div class="col-span-1">
					<form-group
						type="phone"
						leftIcon="call-outline"
						name="phone_no"
						:form="form"
						v-model="form.data.phone_no.value">
						Phone Number
					</form-group>
				</div>
				<div class="col-span-1">
					<form-group
						leftIcon="lock-closed-outline"
						name="bvn"
						:form="form"
						v-model="form.data.bvn.value"
						:disabled="bvnAlreadyProvided"
					>
						BVN
					</form-group>
				</div>
			</div>
			<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
				<div class="col-span-1">
					<form-group
						type="select"
						:options="employmentTypes"
						leftIcon="briefcase-outline"
						name="emp_type"
						:form="form"
						v-model="form.data.emp_type.value">
						Employment Type
					</form-group>
				</div>
				<div class="col-span-1">
					<form-group
						type="money"
						leftIcon="wallet-outline"
						name="salary"
						:form="form"
						v-model="form.data.salary.value">
						Salary
					</form-group>
				</div>
			</div>
			<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
				<div class="col-span-1">
					<form-group
						leftIcon="briefcase-outline"
						name="position"
						:form="form"
						v-model="form.data.position.value">
						Job Title
					</form-group>
				</div>
				<div class="col-span-1">
					<form-group
						type="select"
						:options="daysOfTheMonth"
						select-display-type="grid"
						:select-grid-columns="4"
						leftIcon="calendar-outline"
						name="salary_day"
						v-model="form.data.salary_day.value"
						:form="form"
					>
						Salary Day
					</form-group>
				</div>
			</div>
			<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
				<div class="col-span-1">
					<form-group
						leftIcon="wallet-outline"
						name="account_no"
						:form="form"
						v-model="form.data.account_no.value">
						Account Number
					</form-group>
				</div>
				<div class="col-span-1">
					<form-group
						type="select"
						:options="bankOptions"
						leftIcon="business-outline"
						name="bank_name"
						:form="form"
						v-model="form.data.bank_name.value"
						:loading="banksLoading"
					>
						Bank Name
					</form-group>
				</div>
			</div>
			<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
				<div class="col-span-1">
					<form-group
						type="select"
						:options="idTypes"
						leftIcon="reader-outline"
						name="id_type"
						:form="form"
						v-model="form.data.id_type.value">
						ID Type
					</form-group>
				</div>
				<div class="col-span-1">
					<form-group
						leftIcon="document-text-outline"
						name="id_number"
						:form="form"
						v-model="form.data.id_number.value"
					>
						ID Number
					</form-group>
				</div>
			</div>

			<div class="text-right">
				<button
					type="submit"
					class="button bg-blue-500 hover:bg-blue-600 text-white"
					:disabled="form.loading"
				>
					<span v-if="form.loading">Updating</span>
					<span v-else>Update Profile</span>
				</button>
			</div>

		</form>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="modal">

			<img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Success!
			</div>
			<div class="text-sm mb-10">
				Profile was successfully updated.
				An admin will approve your profile soon.
			</div>

			<router-link :to="{name: 'dashboard'}" class="button bg-blue-500 hover:bg-blue-600 text-white">
				Go to Dashboard
			</router-link>
			
		</modal>

	</div>
</template>

<script>
	import { mapState, mapActions } from 'vuex';

	export default {
		data() {
			return {
				// banks: [],
				banksError: null,
				banksLoading: false,
				employmentTypes: [
					'Full Time',
					'Part Time',
					'Contract',
				],
				form: this.$options.basicForm([
					{name: 'phone_no', rules: 'required|phone'},
					{name: 'bvn', rules: 'required|number|length:11'},
					{name: 'emp_type', rules: 'required'},
					{name: 'salary', rules: 'required|number|min:50000'},
					{name: 'salary_day', rules: 'required|number'},
					{name: 'position', rules: 'required'},
					// {name: 'current_loan_facility', rules: 'required'},
					{name: 'account_no', rules: 'required|number|length:10'},
					{name: 'bank_name', rules: 'required'},
					{name: 'gender', rules: 'required'},
					{name: 'marital_status', rules: 'required'},
					{name: 'date_of_birth', rules: 'required'},
					{name: 'id_type', rules: 'required'},
					{name: 'id_number', rules: 'required'},
				]),
				idTypes: [
					{title: 'National ID Card', value: 'national_id_card'},
					{title: 'Driver’s License', value: 'drivers_license'},
					{title: 'Permanent Voter’s Card', value: 'voters_card'},
					{title: 'Nigerian International Passport', value: 'international_passport'},
				],
				loanTypes: [
					{
						title: 'None',
						value: 'none'
					},
					{
						title: 'Salary Advance',
						value: 'salary_advance'
					},
					{
						title: 'Car',
						value: 'car'
					},
					{
						title: 'Housing',
						value: 'housing'
					},
					{
						title: 'Emergency',
						value: 'emergency'
					},
					{
						title: 'Health',
						value: 'health'
					},
					{
						title: 'Education',
						value: 'education'
					}
				],
				genders: [
					{ title: 'Male', value: 'male' },
					{ title: 'Female', value: 'female' },
					{ title: 'Other', value: 'other' },
				],
				marital_statuses: [
					{ title: 'Single', value: 'single' },
					{ title: 'Engaged', value: 'engaged' },
					{ title: 'Married', value: 'married' },
					{ title: 'Divorced', value: 'divorced' },
					{ title: 'Widowed', value: 'widowed' },
				],
			}
		},
		computed: {
			...mapState(['banks']),
			bankOptions() {
				return this.banks.length ? this.banks.map( bank => ({
					title: bank.name,
					value: bank.code
				}) ) : [];
			},
			bvnAlreadyProvided() {
				return !!this.user.profile?.bvn;
			},
			email() {
				return this.user.email;
			},
			errorMessage() {
				if (this.form.error?.toString().match(/Error: Network Error/i)) {
					return 'Please check your internet connection';
				}

				const errors = this.form.error?.response?.data?.errors;
				if (errors && Object.keys(errors).length) {
					return 'Please check the form for incorrect or missing data';
				}

				return null;
			},
			fullName() {
				return this.user.name;
			},
			daysOfTheMonth() {
				const days = [];

				for (var i = 0; i < 28; i++) {
					const day = i+1;
					const value = day;
					const title = day.toPosition();
					days.push({ title, value });
				}

				return days;
			}
		},
		mounted() {
			// this.getBanks();
			this.mapProfileData();
		},
		methods: {
			...mapActions('session', ['updateUser']),

			async getBanks() {
				this.banksLoading = true;

				await this.$get({
					url: 'https://api.paystack.co/bank',
					success: response => {
						this.banks = response.data.data;
					},
					error: error => {
						this.banksError = error;
					}
				});

				this.banksLoading = false;
			},
			mapProfileData() {
				// if (!this.user.profile) {
				// 	return false;
				// }

				for (let key in this.form.data) {
					this.form.data[key].value = this.user.profile?.[key] || this.user[key] || '';
				}

			},
			async submit() {
				if (!this.validateForm(this.form)) {
					return false;
				}

				this.form.error = null;
				
				this.form.loading = true;

				const data = JSON.stringify({
					user_id: this.user.id,
					...this.getFormData()
				})

				await this.$put({
					url: `${this.$baseurl}/staff/profile/update/${this.user.id}`,
					data,
					headers: {
						...this.headers,
						'Content-Type': 'application/json'
					},
					success: (response) => {
						this.updateUser({ profile: response.data.profile })
						this.$refs.modal.open();
					},
					error: error => {
						this.form.error = error;
						this.mapFormErrors(this.form, error?.response?.data?.errors);
					}
				});

				this.form.loading = false;
			},
		}
	}
</script>