<template>
	<div>
		<div class="card border border-blue-200 pt-10 pb-10 px-10 md:px-16 mb-8">
			
			<form @submit.prevent="submitGovtID" class="mb-10" key="govt_id">

				<div class="flex flex-row justify-between">
					<div class="text-sm mb-4">Government Issued ID</div>
					<!-- <a href="#!" class="underline text-xs mb-4" @click.prevent="help">Need help?</a> -->
				</div>

				<sm-loader class="mb-20" v-if="documents.loading" />
				<div class="border border-blue-200 rounded px-5 py-3 mb-8 text-sm" v-else-if="lastGovtId">
					<div class="opacity-50 mb-4">Last Uploaded</div>
					<div class="flex flex-row items-center justify-between">
						<a :href="lastGovtId.url" target="_blank" class="underline mr-3">{{ lastGovtId.filename }}</a>
						<!-- <span class="text-red-500" v-if="lastStatementIsOutdated">
							Your bank statement is outdated!
						</span> -->
					</div>
				</div>

				<dropzone
					accept=".png, .jpg, .jpeg"
					v-model="govtIDForm.data.govt_id.value"
					:uploading="govtIDForm.loading"
					:uploaded="govtIDForm.success"
				></dropzone>

				<template v-if="getFormError(govtIDForm)">
					<div class="alert alert-red-soft mb-10">
						<span class="alert-icon mr-3">!</span>
						<span>{{ getFormError(govtIDForm) }}</span>
					</div>
				</template>

				<div class="text-right">
					<button
						type="button"
						class="btn btn-gray-soft mr-10"
						:disabled="!govtIDForm.preview"
						@click.prevent="previewGovtId"
					>
						Preview
					</button>
					<button
						type="submit"
						class="btn btn-blue"
						:disabled="!govtIDForm.data.govt_id.value"
					>
						Submit
					</button>
				</div>

			</form>

			<form @submit.prevent="submitStaffID" class="mb-10" key="staff_id">

				<div class="flex flex-row justify-between">
					<div class="text-sm mb-4">Work/Staff ID</div>
					<!-- <a href="#!" class="underline text-xs mb-4" @click.prevent="help">Need help?</a> -->
				</div>

				<sm-loader class="mb-20" v-if="documents.loading" />
				<div class="border border-blue-200 rounded px-5 py-3 mb-8 text-sm" v-else-if="lastWorkId">
					<div class="opacity-50 mb-4">Last Uploaded</div>
					<div class="flex flex-row items-center justify-between">
						<a :href="lastWorkId.url" target="_blank" class="underline mr-3">{{ lastWorkId.filename }}</a>
						<!-- <span class="text-red-500" v-if="lastStatementIsOutdated">
							Your bank statement is outdated!
						</span> -->
					</div>
				</div>

				<dropzone
					accept=".png, .jpg, .jpeg"
					v-model="staffIDForm.data.staff_id.value"
					:uploading="staffIDForm.loading"
					:uploaded="staffIDForm.success"
				></dropzone>

				<template v-if="getFormError(staffIDForm)">
					<div class="alert alert-red-soft mb-10">
						<span class="alert-icon mr-3">!</span>
						<span>{{ getFormError(staffIDForm) }}</span>
					</div>
				</template>

				<div class="text-right">
					<button
						type="button"
						class="btn btn-gray-soft mr-10"
						:disabled="!staffIDForm.preview"
						@click.prevent="previewStaffId"
					>
						Preview
					</button>
					<button
						type="submit"
						class="btn btn-blue"
						:disabled="!staffIDForm.data.staff_id.value"
					>
						Submit
					</button>
				</div>

			</form>

			<form @submit.prevent="submitBankStatement" key="bank_statement">

				<template v-if="getFormError(bankStatementForm)">
					<div class="alert alert-red-soft mb-10">
						<span class="alert-icon">!</span>
						<span>{{ getFormError(bankStatementForm) }}</span>
					</div>
				</template>

				<div class="flex flex-row justify-between">
					<div class="text-sm mb-4">Bank Statement</div>
					<!-- <a href="#!" class="underline text-xs mb-4" @click.prevent="help">Need help?</a> -->
				</div>

				<sm-loader class="mb-20" v-if="lastStatementLoading" />
				<div class="border border-blue-200 rounded px-5 py-3 mb-8 text-sm" v-else-if="lastStatement">
					<div class="opacity-50 mb-4">Last Uploaded</div>
					<div class="flex flex-row items-center justify-between">
						<a :href="lastStatement.url" target="_blank" class="underline mr-3">{{ lastStatement.filename }}</a>
						<span class="text-red-500" v-if="lastStatementIsOutdated">
							Your bank statement is outdated!
						</span>
					</div>
				</div>

				<dropzone
					accept=".pdf"
					v-model="bankStatementForm.data.bank_statement.value"
					:uploading="bankStatementForm.loading"
					:uploaded="bankStatementForm.success"
				></dropzone>

				<div class="text-right">
					<button
						type="submit"
						class="btn btn-blue"
						:disabled="!bankStatementForm.data.bank_statement.value"
					>
						Submit
					</button>
				</div>

			</form>

		</div>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="modal">

			<img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Success!
			</div>
			<div class="text-sm mb-10">
				Document was successfully uploaded.
			</div>

			<router-link :to="{name: 'dashboard'}" class="btn btn-blue btn-md">
				Go to Dashboard
			</router-link>
			
		</modal>

	</div>
</template>

<script>
	import { mapActions } from 'vuex';

	export default {
		data() {
			return {
				lastStatementLoading: false,
				lastStatement: null,
				documents: this.$options.resource([]),
				bankStatementForm: this.$options.basicForm([
					{name: 'bank_statement', value: null}
				]),
				staffIDForm: this.$options.basicForm([
					{name: 'staff_id', value: null}
				], {preview: null}),
				govtIDForm: this.$options.basicForm([
					{name: 'govt_id', value: null}
				], {preview: null}),
			}
		},
		computed: {
			lastStatementIsOutdated() {
				return false;
				// if (!this.lastStatement) {
				// 	return false;
				// }

				// const date = (new Date(this.lastStatement.created_at)).getTime();
				// const now = (new Date()).getTime();
				// const diff = now - date;

				// console.log(date)
				// console.log(now)
				// console.log(diff)

				// return diff >= 2592000; 
			},
			bankStatements() {
				return this.documents.data.filter(document => document.type == 'bank_statement');
			},
			govtIds() {
				return this.documents.data.filter(document => document.type == 'govt_id');
			},
			lastGovtId() {
				return this.govtIds[this.govtIds.length - 1];
			},
			workIds() {
				return this.documents.data.filter(document => document.type == 'work_id');
			},
			lastWorkId() {
				return this.workIds[this.workIds.length - 1];
			},
		},
		mounted() {
			this.getDocuments();
			this.getLastStatement();
		},
		watch: {
			'govtIDForm.data.govt_id.value' (file) {
				if (!file) {
					return;
				}

				this.govtIDForm.preview = null;

				const reader = new FileReader();

				reader.onload = (event) => {
					this.govtIDForm.preview = event.target.result;
				}

				reader.readAsDataURL(file);

			},
			'staffIDForm.data.staff_id.value' (file) {
				if (!file) {
					return;
				}

				this.staffIDForm.preview = null;

				const reader = new FileReader();

				reader.onload = (event) => {
					this.staffIDForm.preview = event.target.result;
				}

				reader.readAsDataURL(file);

			},
		},
		methods: {
			...mapActions('session', ['updateUser']),

			async getDocuments() {
				this.documents.loading = true;

				await this.$get({
					url: `${this.$baseurl}/staff/documents/all/${this.user.id}`,
					headers: this.headers,
					success: response => {
						this.documents.data = response.data.docs;
					},
					error: error => {
						this.documents.error = error;
					}
				});

				this.documents.loading = false;
			},
			async getLastStatement() {
				this.lastStatementLoading = true;

				await this.$get({
					url: `${this.$baseurl}/staff/documents/bank_statement/last/${this.user.id}`,
					headers: this.headers,
					success: response => {
						this.lastStatement = response.data.bank_statement;
					},
					// error: error => {
					// 	this.banksError = error;
					// }
				});

				this.lastStatementLoading = false;
			},
			mapProfileData() {
				for (let key in this.form.data) {
					this.form.data[key].value = this.user.profile?.[key] || this.user[key] || '';
				}

			},
			previewGovtId() {
				const preview = window.open();

				preview.document.body.innerHTML = `<img src="${this.govtIDForm.preview}">`;
			},
			previewStaffId() {
				const preview = window.open();

				preview.document.body.innerHTML = `<img src="${this.staffIDForm.preview}">`;
			},
			async submitGovtID() {
				if (!this.validateForm(this.govtIDForm)) {
					return false;
				}

				this.govtIDForm.error = null;
				
				this.govtIDForm.loading = true;

				const data = new FormData();

				data.append('govt_id', this.govtIDForm.data.govt_id.value);

				await this.$post({
					url: `${this.$baseurl}/staff/documents/govt_id/${this.user.id}`,
					data,
					headers: {
						...this.headers,
						'Content-Type': 'application/json'
					},
					success: () => {
						this.$refs.modal.open();
						this.getDocuments()
					},
					error: error => {
						this.govtIDForm.error = error;
						this.mapFormErrors(this.govtIDForm, error?.response?.data?.errors);
					}
				});

				this.govtIDForm.loading = false;
			},
			async submitStaffID() {
				if (!this.validateForm(this.staffIDForm)) {
					return false;
				}

				this.staffIDForm.error = null;
				
				this.staffIDForm.loading = true;

				const data = new FormData();

				data.append('work_id', this.staffIDForm.data.staff_id.value);

				await this.$post({
					url: `${this.$baseurl}/staff/documents/work_id/${this.user.id}`,
					data,
					headers: {
						...this.headers,
						'Content-Type': 'application/json'
					},
					success: () => {
						this.$refs.modal.open();
						this.getDocuments()
					},
					error: error => {
						this.staffIDForm.error = error;
						this.mapFormErrors(this.staffIDForm, error?.response?.data?.errors);
					}
				});

				this.staffIDForm.loading = false;
			},
			async submitBankStatement() {
				if (!this.validateForm(this.bankStatementForm)) {
					return false;
				}

				this.bankStatementForm.error = null;
				
				this.bankStatementForm.loading = true;

				const data = new FormData();

				data.append('bank_statement', this.bankStatementForm.data.bank_statement.value);

				await this.$post({
					url: `${this.$baseurl}/staff/documents/bank_statement/${this.user.id}`,
					data,
					headers: {
						...this.headers,
						'Content-Type': 'application/json'
					},
					success: () => {
						this.$refs.modal.open();
						this.getDocuments();
						this.getLastStatement()
					},
					error: error => {
						console.log(error);
						this.bankStatementForm.error = error;
						this.mapFormErrors(this.bankStatementForm, error?.response?.data?.errors);
					}
				});

				this.bankStatementForm.loading = false;
			},
		}
	}
</script>